import React from "react"
import { Global, css } from "@emotion/core"
import Helmet from "react-helmet"
import Header from "./header"
import useSiteMetadata from "../hooks/use-sitemetadata"

const Layout = ({ postTitle, hero, children }) => {
  const seoTitle = postTitle === undefined ? "" : `${postTitle} | `
  console.log(seoTitle)
  const { title, description } = useSiteMetadata()
  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          * + p {
            margin-top: 1rem;
          }

          html,
          body {
            background: #fff;
            margin: 0;
            color: #222;
            font-family: "Open Sans",
              sans-serif;
            font-size: 18px;
            line-height: 1.4;
            * {
              font-family: "Open Sans",
              sans-serif;
            }
            /* Remove marge of Gatsby main dev */
            > div {
              margin-top: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #222;
              line-height: 1.1;
              font-family: Inter,
              sans-serif;
              margin: 0.5em 0;
              
            }

            strong {
              color: #000;
            }

            li {
              margin-top: 0.25rem;
            }
            a {
              color: #6D90BC;
              font-weight:600;
              transition: all 0.3s ease-in-out;
              &:visited {
                color: #6D90BC;
              }
              &:hover {
                color: tomato;
              }
            }
          }
          
          .align-right.gatsby-image-wrapper {
            margin: 1em 0;
            @media (min-width: 600px) {
              margin:0  0 1em 2em;
              width: 30%;
              height: auto;
              float: right;
              max-width: 450px;
            }
          }

          .align-left.gatsby-image-wrapper {
            margin: 1em 0;
            @media (min-width: 600px) {
              margin:0  2em 1em 0;
              width: 30%;
              height: auto;
              float: left;
              max-width: 450px;
            }
          }
        `}
      />

      <Helmet>
        <html lang="en" />
        <title>{`${seoTitle} ${title}`}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />

      <main
        css={css`
          margin: 0 auto;
          padding: 2em 1em;
          background: #ffffff;
          max-width: 1200px;
          display: flow-root;
        `}
      >
        {children}
      </main>
      <footer
        css={css`
              padding: 2em;
              margin: 0 auto;
              max-width: 1200px;
              
              text-align: center;
              p {
                  font-size:0.9em;
                  margin:0;
                  padding: 0;
              }
              
              @media(min-width:600px){
                  display: flex;
                  justify-content: space-between;
    
                > div {
                  margin-top: 0;
                }
              }
        `}
      >
      <div>
           <p>&copy; Matt Edwards {new Date().getFullYear()}</p>
      </div>
      <div>
          <p>Contact Me: <a href="mailto:matt@emptyhead.com.au">Matt Edwards</a></p>
      </div>
      </footer>
    </>
  )
}

export default Layout
