import React from "react"
import styled from "@emotion/styled"
//import { css } from "@emotion/core"
import { Link } from "gatsby"

const HeaderNav = styled("header")`
  margin: 0;
  padding: 0 1em;
  h1 {
    color: #000;
    margin-top: 1.5rem;
    font-size: clamp(1em, 1.4em + 3vw, 5em);
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    span {
      font-weight: 300;
      color: tomato;
    }
    a {
      color: #000;
      text-decoration: none;
      &:visited {
        color: #000;
      }
    }
  }
  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0 0;
  }
`

const NavLink = styled(Link)`
  color: #000;
  margin: 0;
  padding: 0.5rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-family: Inter,
              sans-serif;
  font-weight: 500;
  &:visited {
    color: #000;
  }
  &.current-page,
  &:hover {
    color: #6D90BC;
    background: transparent;
  }
`

const Header = () => {
  return (
    <HeaderNav>
      <h1><Link to="/">Matt<span>Edwards</span></Link></h1>
      <nav>
        <NavLink to="/" activeClassName="current-page">
          Home
        </NavLink>
        <NavLink to="/about" activeClassName="current-page">
          About
        </NavLink>
        <NavLink to="/work-journal" activeClassName="current-page">
          Work Journal
        </NavLink>
        <NavLink to="/contact" activeClassName="current-page">
          Contact
        </NavLink>
      </nav>
    </HeaderNav>
  )
}

export default Header
